import { StateUf, states } from "@components/Sections/UfsPartners/states";
import { Partner } from "@src/types/strapi/collections/partners";
import { Dispatch, ReactNode, SetStateAction, createContext, useState } from "react";

type typePartnersContext = {
  partners: Partner[];
  setPartners: Dispatch<SetStateAction<Partner[]>>;
  stateSelected: StateUf;
  setStateSelected: Dispatch<SetStateAction<StateUf>>;
  partnerSelected: Partner;
  setPartnerSelected: Dispatch<SetStateAction<Partner>>;
}
export const FIRST_STATE = states[0]
export const PartnersContext = createContext({} as typePartnersContext);
export const PartnersProvider = ({ children }: { children: ReactNode }) => {
  const [ partners, setPartners ] = useState<Partner[]>([]);
  const [ stateSelected, setStateSelected ] = useState(FIRST_STATE);
  const [ partnerSelected, setPartnerSelected ] = useState<Partner>({} as Partner);
  return (
    <PartnersContext.Provider value={{ partners, setPartners, stateSelected, setStateSelected, partnerSelected, setPartnerSelected }}>
      { children }
    </PartnersContext.Provider>
  )
}