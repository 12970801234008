import ReactMarkdown from 'react-markdown'
import html from 'rehype-raw'
import gfm from 'remark-gfm'
import styles from './Markdown.module.scss'

interface IProps {
  children: string
}

const Markdown: React.FC<IProps> = ({ children }) => {
  return (
    <ReactMarkdown
      className={styles.markdown}
      data-testid="markdown"
      rehypePlugins={[html]}
      remarkPlugins={[gfm]}
      components={{
        // eslint-disable-next-line react/display-name
        image: ({ src }: any) => <img src={src} />,
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
